<template>
  <div>
    <v-dialog v-model="isPrint" persistent max-width="500">
      <v-card>
        <v-card-title class="px-2">
          ตัวเลือกการปริ้น
        </v-card-title>
        <v-divider></v-divider>
        <v-row class="mx-1 my-1">
          <v-col cols="12" md="4">
            <div class="font-weight-semibold text-decoration-underline">เลือกประเภทการปริ้น</div>
            <v-radio-group v-model="printType" hide-details>
              <v-radio
                  label="ใบกำกับภาษี"
                  :value="1"
              ></v-radio>
              <v-radio
                  label="ใบเสร็จรับเงิน"
                  :value="2"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="12" md="4">
            <div class="font-weight-semibold text-decoration-underline">เลือกภาษา</div>
            <v-radio-group v-model="langSelection" hide-details>
              <v-radio
                  label="ไทย"
                  :value="1"
              ></v-radio>
              <v-radio
                  label="한국"
                  :value="2"
              ></v-radio>
              <v-radio
                  label="ไทย / 한국"
                  :value="3"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="12" md="4">
            <div class="font-weight-semibold text-decoration-underline">เลือกขนาด</div>
            <v-radio-group v-model="sizeSelection" hide-details :disabled="printType==1">
              <v-radio
                  label="A4"
                  :value="1"
              ></v-radio>
              <v-radio
                  label="80mm."
                  :value="2"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="print">ปริ้น</v-btn>
          <v-btn color="secondary" outlined @click="$emit('update:isPrint',false)">ยกเลิก</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from "@vue/composition-api";
import router from "@/router"

export default {
  model: {
    prop: 'isPrint',
    event: 'update:isPrint',
  },
  props: {
    isPrint: {
      type: Boolean,
      default: false,
    },
    idList: {
      type: Array,
      default: () => []
    },
  },
  components: {},
  setup(props, { root, emit }) {
    const printType = ref(1)
    const langSelection = ref(1)
    const sizeSelection = ref(1)

    const print = () => {
      let data = {
        id: props.idList.toString(),
        lang: 'th'
      }
      if (printType.value === 2) {
        if (sizeSelection.value == 1) {
          if (langSelection.value === 1) {
            data.lang = 'th'
            const routeData = router.resolve(
                {
                  name: 'order-print-th',
                  query: data,
                })
            window.open(routeData.href, '_blank')
          } else if (langSelection.value === 2) {
            data.lang = 'ko'
            const routeData = router.resolve(
                {
                  name: 'order-print-krw',
                  query: data,
                })
            window.open(routeData.href, '_blank')
          } else {
            data.lang = 'ko'
            const routeData = router.resolve(
                {
                  name: 'print2',
                  query: data,
                })
            window.open(routeData.href, '_blank')
          }
        } else {
          if (langSelection.value === 1) {
            data.lang = 'th'
            const routeData = router.resolve(
                {
                  name: 'order-print-th-80',
                  query: data,
                })
            window.open(routeData.href, '_blank')
          } else if (langSelection.value === 2) {
            data.lang = 'ko'
            const routeData = router.resolve(
                {
                  name: 'order-print-krw-80',
                  query: data,
                })
            window.open(routeData.href, '_blank')
          } else {
            data.lang = 'ko'
            const routeData = router.resolve(
                {
                  name: 'print3',
                  query: data,
                })
            window.open(routeData.href, '_blank')
          }
        }
      } else {
        if (langSelection.value === 1) {
          data.lang = 'th'
          const routeData = router.resolve(
              {
                name: 'vat-print-th',
                query: data,
              })
          window.open(routeData.href, '_blank')
        } else if (langSelection.value === 2) {
          data.lang = 'ko'
          const routeData = router.resolve(
              {
                name: 'vat-print-krw',
                query: data,
              })
          window.open(routeData.href, '_blank')
        } else {
          data.lang = 'ko'
          const routeData = router.resolve(
              {
                name: 'print',
                query: data,
              })
          window.open(routeData.href, '_blank')
        }

      }
    }

    return { printType, langSelection, print, sizeSelection }
  }
  ,

}
</script>

<style scoped>

</style>
