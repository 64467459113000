import { ref, onMounted } from "@vue/composition-api"
import footerDataTables from "@/fake-db/footerDataTables.json";
import { api } from "@/services/api";
import store from "@/store";

export default function useOrderList() {
    const textSearch = ref('')
    const headers = ref([
        {
            text: 'เลขออเดอร์',
            value: 'order_code',
            width: '140',
        },
        {
            text: 'ลูกค้า',
            value: 'customer_fname',
            width: '200',
        },
        {
            text: 'ชื่อผู้รับสินค้า',
            value: 'order_transportation_name',
            width: '200',
        },
        {
            text: 'ที่อยู่',
            value: 'order_transportation_full_address',
            width: '200',
        },

        {
            text: 'วันที่สร้างออเดอร์',
            value: 'CREATE',
            width: '160',
        },
        {
            text: 'วันที่จัดส่ง',
            value: 'order_transportation_date',
            width: '160',
        },
        {
            text: 'รวมคารวม',
            value: 'sum_total_transportation',
            width: '130',
            align: 'end',
        },
        {
            text: 'ผู้ทำรายการ',
            value: 'user_create',
            width: '150',
        },
        {
            text: 'สถานะออเดอร์',
            value: 'order_payment_status',
            width: '170',
            align: 'center',
        },
        {
            text: 'สถานะคอนเฟิร์ม',
            value: 'order_payment_confirm',
            width: '170',
            align: 'center',
        },
        {
            text: 'สถานะจัดส่ง',
            value: 'order_transportation_status',
            width: '170',
            align: 'center',
        },
        {
            text: 'ตัวเลือก',
            value: 'actions',
            width: '260',
            align: 'center fixed',
        },
    ])
    const itemsPerPage = ref(10)
    const dataList = ref([])
    const loading = ref(false)
    const isShowDate1 = ref(false)
    const isShowDate2 = ref(false)
    const isPayment = ref(false)
    const isUpdateStatus = ref(false)
    const dataEdit = ref({})
    const footer = ref(footerDataTables)
    const isEdit = ref(false)
    const statusCheckConfirm = ref(false)
    const isAdd = ref(false)
    const isConfirm = ref(false)
    const isChecking = ref(false)
    const isPrint = ref(false)
    const isDeleteOrder = ref(false)
    const status = ref('1')
    const orderStatusSelection = ref('')
    const orderStatusConfirmSelection = ref('')
    const orderStatusTransportSelection = ref('')
    const selectedRows = ref([])
    const idList = ref([])
    const moment = require('moment')
    const date_start = ref(moment(new Date()).format('YYYY-MM-DD'))
    const date_end = ref(moment(new Date()).format('YYYY-MM-DD'))


    onMounted(() => {
        getOrders()
    })

    const clearDate = () => {
        date_start.value = moment(new Date()).format('YYYY-MM-DD')
        date_end.value = moment(new Date()).format('YYYY-MM-DD')
        getOrders()
    }

    const changeStatus = (id) => {
        status.value = id
        getOrders()
    }

    const getOrders = async () => {
        loading.value = true
        await api.get({
            path: '/orders',
            param: `?search=${ textSearch.value }&order_payment_status=${ orderStatusSelection.value }&order_payment_confirm=${ orderStatusConfirmSelection.value }&transportation_status=${ orderStatusTransportSelection.value }&startDate=${ date_start.value }&endDate=${ date_end.value }&currency_id=${ localStorage.getItem('currencyId') }`,
        }).then(({ data }) => {
            dataList.value = data
            loading.value = false
        }).catch(err => {
            console.log('error : ', err)
            loading.value = false
        })
    }

    const mapId = (id) => {
        if (id) {
            idList.value = [id]
        } else {
            idList.value = selectedRows.value.map(item => item.order_id)
        }
        isPrint.value = true
    }


    return {
        getOrders,
        changeStatus,
        textSearch,
        headers,
        itemsPerPage,
        dataList,
        loading,
        isUpdateStatus,
        isPayment,
        isShowDate1,
        isShowDate2,
        dataEdit,
        footer,
        isEdit,
        statusCheckConfirm,
        isAdd,
        isConfirm,
        isChecking,
        selectedRows,
        orderStatusSelection,
        orderStatusConfirmSelection,
        isPrint,
        idList,
        isDeleteOrder,
        mapId,
        clearDate,
        date_start,
        date_end,
        orderStatusTransportSelection,
    }
}