<template>
  <v-chip
      :color="status === '0'?'warning':status === '1'?'success':'error'"
      :class="status==='0'?`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg warning--text`:
      status==='1'?`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg success--text`:
      `v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg error--text`"
      small
  >
    {{ status === '0' ? `รอคอนเฟิร์ม` : status === '1' ? `คอนเฟิร์มแล้ว` : `ยกเลิก` }}
  </v-chip>
</template>

<script>
import {mdiCheck, mdiClose} from '@mdi/js'

export default {
  props: {
    status: {
      type: String,
      default: ''
    }
  },
  setup() {
    return {
      icons: {
        mdiCheck,
        mdiClose,
      },
    }
  },
}
</script>
