<template>
    <div>
        <v-dialog v-model="isDelete" persistent max-width='400'>
            <v-card>
                <v-card-title class="align-center justify-center">
                    ยืนยันกาารยกเลิกออเดอร์
                </v-card-title>
                <v-card-text class="text-center">
                    คุณต้องการยกเลิกออเดอร์ {{ orderDetail.order_code }} หรือไม่ ?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color='error' @click='cancelOrder' :loading='loading' :disabled='loading'>
                        ยืนยัน
                    </v-btn>
                    <v-btn color='secondary' outlined @click='$emit(`update:isDelete`,false)'>
                        ยกเลิก
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { ref } from "@vue/composition-api"
import { api } from "@/services/api";
import store from "@/store";

export default {
    model: {
        prop: 'isDelete',
        event: 'update:isDelete'
    },
    props: {
        isDelete: {
            type: Boolean,
            default: false
        },
        orderDetail: {
            type: Object,
            default: () => {
            }
        },
    },
    setup(props, { _, emit }) {

        const loading = ref(false)

        const cancelOrder = () => {
            loading.value = true
            api.delete({
                path: '/order/unActive',
                param: props.orderDetail.order_id,
            }).then(res => {
                store.commit('app/ALERT', {
                    message: res.message,
                    color: res.response ? 'success' : 'error',
                })
                if (res.response) {
                    emit('update:isDelete', false)
                    emit('onUpdate')
                } else {
                    loading.value = false
                    emit('update:isDelete', false)
                }
                loading.value = false
            })
        }

        return {
            loading,
            cancelOrder
        }


    }
}

</script>