<template>
    <div>
        <v-card>
            <v-card-title>
        <span>
          รายการสั่งซื้อ
        </span>
                <v-spacer></v-spacer>
            </v-card-title>
            <v-row class='px-2 pb-2'>
                <v-col cols='12' md='6' lg='3'>
                    <v-text-field v-model='textSearch'
                                  filled
                                  @input='getOrders'
                                  label='ค้นหารายการสั่งซื้อ'
                                  dense
                                  hide-details
                                  placeholder='คำค้นหา'></v-text-field>
                </v-col>
                <v-col cols='12' md='6' lg='3'>
                    <v-select filled label='สถานะออเดอร์' :items='orderStatus'
                              dense
                              hide-details
                              item-text="label"
                              item-value="value"
                              v-model='orderStatusSelection'
                              @change='getOrders'
                    ></v-select>
                </v-col>
                <v-col cols='12' md='6' lg='3'>
                    <v-select filled label='สถานะคอนเฟิร์ม' :items='orderStatusConfirm'
                              dense
                              @click="getOrders"
                              hide-details
                              item-text="label"
                              item-value="value"
                              v-model='orderStatusConfirmSelection'
                              @change='getOrders'
                    ></v-select>
                </v-col>
                <v-col cols='12' md='6' lg='3'>
                    <v-select filled label='สถานะการจัดส่ง' :items='orderStatusTransport'
                              dense
                              @click="getOrders"
                              hide-details
                              item-text="label"
                              item-value="value"
                              v-model='orderStatusTransportSelection'
                              @change='getOrders'
                    ></v-select>
                </v-col>
                <v-col
                        cols="12"
                        md="6"
                        lg="3"
                >
                    <v-dialog
                            ref="dateStart"
                            v-model.trim="isShowDate1"
                            :return-value.sync="date_start"
                            width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                    v-model.trim="date_start"
                                    label="วันที่เริ่มต้น"
                                    filled
                                    dense
                                    hide-details
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                            >
                            </v-text-field>
                        </template>
                        <v-date-picker
                                v-model.trim="date_start"
                                locale="th"
                                scrollable
                        >
                            <v-spacer></v-spacer>
                            <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.dateStart.save(date_start);getOrders()"
                            >
                                ยืนยัน
                            </v-btn>
                            <v-btn
                                    text
                                    color="secondary"
                                    @click="isShowDate1 = false"
                            >
                                ยกเลิก
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-date-picker>
                    </v-dialog>
                </v-col>
                <v-col
                        cols="12"
                        md="6"
                        lg="3"
                >
                    <v-dialog
                            ref="dateEnd"
                            v-model.trim="isShowDate2"
                            :return-value.sync="date_end"
                            width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                    v-model.trim="date_end"
                                    label="วันที่สิ้นสุด"
                                    readonly
                                    hide-details
                                    filled
                                    dense
                                    v-bind="attrs"
                                    v-on="on"
                            >

                            </v-text-field>
                        </template>
                        <v-date-picker
                                v-model.trim="date_end"
                                locale="th"
                                scrollable
                        >
                            <v-spacer></v-spacer>

                            <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.dateEnd.save(date_end);getOrders()"
                            >
                                ยืนยัน
                            </v-btn>
                            <v-btn
                                    text
                                    color="secondary"
                                    @click="isShowDate2 = false"
                            >
                                ยกเลิก
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-date-picker>
                    </v-dialog>
                </v-col>
                <v-col
                        cols="12"
                        md="6"
                        lg="3"
                        class="d-flex align-center"
                >
                    <v-btn color="primary" @click="date_start = '';date_end = '';getOrders()" class="me-2">
                        <v-icon class="me-2">
                            {{ mdiCalendarBlank }}
                        </v-icon>
                        วันที่ทั้งหมด
                    </v-btn>
                    <v-btn
                            color="info"
                            class="me-2"
                            @click="clearDate()"

                    >
                        <v-icon class="me-2">
                            {{ mdiCalendarBlank }}
                        </v-icon>
                        วันนี้
                    </v-btn>
                    <v-btn
                            color="accent"
                            :disabled="!selectedRows.length"
                            @click="mapId(null)"
                    >
                        <v-icon class="me-2">
                            {{ mdiPrinter }}
                        </v-icon>
                        สั่งพิมพ์
                    </v-btn>
                </v-col>
            </v-row>
            <v-data-table
                    v-model="selectedRows"
                    item-key="order_id"
                    show-select
                    :headers='headers'
                    :items='dataList'
                    :items-per-page='itemsPerPage'
                    :loading='loading'
                    no-data-text='ไม่มีข้อมูล !'
                    loading-text='กำลังโหลดข้อมูล...'
                    :footer-props='footer'
                    no-results-text='ไม่มีข้อมูล !'
                    class='elevation-1'
            >
                <template #[`item.customer_fname`]='{ item }'>
                    ({{ item.customer_code }}) {{ item.customer_fname }}
                </template>

                <template #[`item.order_payment_confirm`]='{ item }'>
                    <StatusOrderConfirm :status='String(item.order_payment_confirm)'/>
                </template>
                <template #[`item.order_payment_status`]='{ item }'>
                    <StatusOrder :status='String(item.order_payment_status)'/>
                </template>
                <template #[`item.order_transportation_status`]='{ item }'>
                    <StatusOrderTransport :status='String(item.order_transportation_status)'/>
                </template>

                <template #[`item.sum_total_transportation`]='{ item }'>
                    {{ item.sum_total_transportation | formatPrice }}
                    <CurrentCurrency/>
                </template>
                <template #[`item.CREATE`]='{ item }'>
                    {{ item.CREATE|sumdatetime }}
                </template>
                <template #[`item.order_transportation_date`]='{ item }'>
                    {{ item.order_transportation_date|sumdatetime }}
                </template>
                <template #[`item.actions`]='{ item }'>
                    <v-tooltip top>
                        <template v-slot:activator='{ on, attrs }'>
                            <v-btn
                                    color='primary'
                                    icon
                                    v-bind='attrs'
                                    @click='isConfirm = true;dataEdit=item;statusCheckConfirm = false'
                                    v-on='on'
                            >
                                <v-icon>{{ mdiTextBoxCheck }}</v-icon>
                            </v-btn>
                        </template>
                        <span>รายละเอียดออเดอร์</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator='{ on, attrs }'>
                            <v-btn
                                    color='primary'
                                    icon
                                    v-bind='attrs'
                                    :disabled='item.order_payment_status ===`1` ||item.order_payment_confirm ===`2`'
                                    @click='isPayment = true;dataEdit=item'
                                    v-on='on'
                            >
                                <v-icon>{{ mdiSendOutline }}</v-icon>
                            </v-btn>
                        </template>
                        <span>แจ้งหลักฐานหลักการชำระเงิน</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator='{ on, attrs }'>
                            <v-btn
                                    color='primary'
                                    icon
                                    v-bind='attrs'
                                    :disabled='item.order_payment_status ===`0`||item.order_payment_confirm ===`1`||item.order_payment_confirm ===`2` '
                                    @click='isConfirm = true;dataEdit=item;statusCheckConfirm = true'
                                    v-on='on'
                            >
                                <v-icon>{{ mdiCheckBold }}</v-icon>
                            </v-btn>
                        </template>
                        <span>คอนเฟิร์มออเดอร์</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator='{ on, attrs }'>
                            <v-btn
                                    color='primary'
                                    icon
                                    v-bind='attrs'
                                    @click='mapId(item.order_id)'
                                    v-on='on'
                            >
                                <v-icon>{{ mdiPrinter }}</v-icon>
                            </v-btn>
                        </template>
                        <span>พิมพ์ใบเสร็จ</span>
                    </v-tooltip>
                    <!--                    <v-tooltip top>-->
                    <!--                        <template v-slot:activator='{ on, attrs }'>-->
                    <!--                            <v-btn-->
                    <!--                                    color='primary'-->
                    <!--                                    icon-->
                    <!--                                    @click="$router.push({name:'edit-order',params:{id:item.order_id}})"-->
                    <!--                                    v-bind='attrs'-->
                    <!--                                    v-on='on'-->
                    <!--                            >-->
                    <!--                                <v-icon>{{ mdiPencilOutline }}</v-icon>-->
                    <!--                            </v-btn>-->
                    <!--                        </template>-->
                    <!--                        <span>แก้ไข</span>-->
                    <!--                    </v-tooltip>-->
                    <v-tooltip top>
                        <template v-slot:activator='{ on, attrs }'>
                            <v-btn
                                    color='primary'
                                    @click="$router.push({name:'edit-order',params:{id:item.order_id}})"
                                    icon
                                    v-bind='attrs'
                                    v-on='on'
                            >
                                <v-icon>{{ mdiPencilOutline }}</v-icon>
                            </v-btn>
                        </template>
                        <span>แก้ไขออเดอร์</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator='{ on, attrs }'>
                            <v-btn
                                    color='primary'
                                    @click="dataEdit = item;isDeleteOrder =true"
                                    icon
                                    v-bind='attrs'
                                    v-on='on'
                            >
                                <v-icon>{{ mdiDeleteOutline }}</v-icon>
                            </v-btn>
                        </template>
                        <span>ยกเลิกออเดอร์</span>
                    </v-tooltip>
                </template>
            </v-data-table>
        </v-card>
        <Payment v-model="isPayment" :order-detail="dataEdit" @onAdd="getOrders"/>
        <ConfirmOrder v-model="isConfirm" :data-confirm="dataEdit" @onConfirm="getOrders"
                      :statusCheckConfirm="statusCheckConfirm"/>
        <PrintOptions v-model="isPrint" :idList="idList"/>
        <TransportChecking v-model="isChecking"/>
        <DeleteOrder v-model="isDeleteOrder" :order-detail="dataEdit" @onUpdate="getOrders"/>
    </div>
</template>

<script>
import useOrderList from "./useOrderList"
import FilterStatus from "@/components/FilterStatus"
import {
    mdiSendOutline,
    mdiCalendarBlank,
    mdiCheckBold,
    mdiPrinter,

    mdiTextBoxCheck,
    mdiDeleteOutline,
    mdiPencilOutline
} from '@mdi/js'
import StatusOrder from "@/components/StatusOrder";
import StatusOrderConfirm from "@/components/StatusOrderConfirm";
import StatusOrderTransport from "@/components/StatusOrderTransport";
import { sumdatetime, formatPrice } from "@/services/filters";
import Payment from "./Payment"
import orderStatus from "@/fake-db/orderStatus.json"
import orderStatusConfirm from "@/fake-db/orderStatusConfirm.json"
import orderStatusTransport from "@/fake-db/orderStatusTransport.json"
import ConfirmOrder from "@/views/manager/order/orderList/ConfirmOrder";
import PrintOptions from "@/components/PrintOptions";
import TransportChecking from "@/views/manager/order/orderList/TransportChecking";
import CurrentCurrency from "@/components/CurrentCurrency";
import DeleteOrder from "@/views/manager/order/orderList/DeleteOrder.vue";

export default {
    props: {},
    components: {
        DeleteOrder,
        CurrentCurrency,
        TransportChecking,
        PrintOptions,
        ConfirmOrder,
        FilterStatus,
        StatusOrder,
        Payment,
        StatusOrderConfirm,
        StatusOrderTransport,
        orderStatusTransport
    },
    filters: {
        sumdatetime,
        formatPrice
    },
    setup(_, { root, emit }) {
        return {
            ...useOrderList(),
            orderStatusConfirm,
            orderStatus,
            orderStatusTransport,
            mdiSendOutline,
            mdiCalendarBlank,
            mdiPencilOutline,
            mdiDeleteOutline,
            mdiCheckBold,
            mdiPrinter,
            mdiTextBoxCheck,
        }
    },

}
</script>

<style scoped>

</style>
