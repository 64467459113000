<template>
  <div>
    <v-dialog v-model="isPayment" max-width="800" persistent>
      <v-card>
        <v-card-title class="px-2">
          <span>ข้อมูลการชำระเงิน</span>
          <v-spacer></v-spacer>
          <span class="font-weight-semibold primary--text">
            ราคารวม : {{ orderDetail.sum_total_transportation |formatPrice }}
          </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-form ref="formAddPayment" @submit.prevent="confirmPayment">
          <v-row class="mx-1 my-1">
            <v-col cols="12" md=6>
              <v-text-field
                  v-model="transferName"
                  label="ชื่อผู้โอน"
                  dense
                  filled
                  :rules="[required]"
                  hide-details="auto"
                  placeholder="ชื่อผู้โอน"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md=6>
              <v-select
                  :items="bankList"
                  v-model="bankSelection"
                  label="เลือกธนาคาร"
                  dense
                  return-object
                  item-value="bank_id"
                  item-text="bank_name"
                  filled
                  :rules="[required]"
                  hide-details="auto"
                  placeholder="เลือกธนาคาร"
              ></v-select>
            </v-col>
            <v-col cols="12" md=6>
              <v-text-field
                  v-model="bankSelection.bank_number"
                  label="หมายเลขบัญชี"
                  dense
                  filled
                  readonly
                  hide-details="auto"
                  placeholder="หมายเลขบัญชี"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md=6>
              <v-text-field
                  v-model="bankSelection.bank_full_name"
                  label="ชื่อเจ้าของบัญชี"
                  dense
                  filled
                  readonly
                  hide-details="auto"
                  placeholder="ชื่อเจ้าของบัญชี"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md=6>
              <v-text-field
                  v-model="orderDetail.sum_total_transportation"
                  label="จำนวน"
                  dense
                  filled
                  readonly
                  :rules="[required]"
                  hide-details="auto"
                  placeholder="จำนวน"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md=6>
              <v-select
                  :items="currencyList"
                  v-model="currencySelection"
                  label="เลือกสกุลเงิน"
                  dense
                  return-object
                  item-value="currency_id"
                  item-text="currency_code"
                  filled
                  disabled
                  :rules="[required]"
                  hide-details="auto"
                  placeholder="เลือกสกุลเงิน"
              ></v-select>
            </v-col>
            <v-col cols="12" md=6>
              <v-dialog
                  ref="menu"
                  v-model.trim="menu1"
                  :return-value.sync="paymentDate"
                  width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="paymentDate"
                      filled
                      dense
                      :rules="[required]"
                      :prepend-inner-icon="mdiCalendar"
                      readonly
                      label="วันที่ชำระ"
                      hide-details="auto"
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model.trim="paymentDate"
                    locale="th"
                    scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                      text
                      color="primary"
                      @click="$refs.menu.save(paymentDate)"
                  >
                    ยืนยัน
                  </v-btn>
                  <v-btn
                      text
                      color="secondary"
                      @click="menu1 = false"
                  >
                    ยกเลิก
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="12" md=6>
              <v-dialog
                  ref="menux"
                  v-model.trim="menu2"
                  :return-value.sync="paymentTime"
                  width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="paymentTime"
                      dense
                      filled
                      :rules="[required]"
                      :prepend-inner-icon="mdiClockTimeFour"
                      label="เวลาโอน"
                      v-bind="attrs"
                      readonly
                      hide-details="auto"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                    v-model.trim="paymentTime"
                    scrollable
                    format="24hr"
                >
                  <v-spacer></v-spacer>

                  <v-btn
                      text
                      color="primary"
                      @click="$refs.menux.save(paymentTime)"
                  >
                    ยืนยัน
                  </v-btn>
                  <v-btn
                      text
                      color="secondary"
                      @click="menu2 = false"
                  >
                    ยกเลิก
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-time-picker>
              </v-dialog>
            </v-col>
            <input
                ref='selectImage'
                type='file'
                accept='image/*'
                style='display: none'
                @change='previewFiles'
            />
            <v-col cols='12' class='d-flex align-center justify-center'>

              <v-hover
                  v-slot='{ hover }'
              >
                <v-img
                    v-if='blob_image'
                    contain
                    class='rounded-lg set-block'
                    :src='blob_image'
                    height='200px'
                    width='300px'
                >
                  <v-fade-transition>
                    <v-overlay
                        v-if='hover'
                        absolute
                        color='#212121'
                    >
                      <div class='align-self-center'>
                        <v-btn
                            :class="{ 'show-btns': hover }"
                            icon
                            color='primary'
                            @click='$refs.selectImage.click()'
                        >
                          <v-icon
                              :class="{ 'show-btns': hover }"
                              color='transparent'
                          >
                            {{ mdiPencilOutline }}
                          </v-icon>
                        </v-btn>
                        <v-btn
                            :class="{ 'show-btns': hover }"
                            icon
                            color='primary'
                            @click="blob_image = ''"
                        >
                          <v-icon
                              :class="{ 'show-btns': hover }"
                              color='transparent'
                          >
                            {{ mdiDeleteOutline }}
                          </v-icon>
                        </v-btn>
                      </div>
                    </v-overlay>
                  </v-fade-transition>
                </v-img>
                <v-card
                    v-else
                    :elevation='hover ? 7 : 0'
                    :class="{ 'on-hover': hover }"
                    class='mx-auto upload-files '
                    height='200px'
                    outlined
                    width='300px'
                    @click='$refs.selectImage.click()'
                >
                  <div
                      align='center'
                      class='pt-12'
                  >
                    <div class='text-lg '>
                      อัพโหลดรูปภาพหลักฐานการชำระเงิน
                    </div>
                    <div class='text-md error--text'>
                      ไฟล์ขนาดไม่เกิน 2 MB
                    </div>
                    <v-icon
                        x-large
                        color='primary'
                        class='pt-2 '
                    >
                      {{ mdiCloudUploadOutline }}
                    </v-icon>
                  </div>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" type="submit" :loading="loading" :disabled="loading">ยืนยัน
            </v-btn>
<!--            <v-btn color="error" bottom right @click="isCancel = true">ยกเลิกออเดอร์</v-btn>-->
            <v-btn color="secondary" outlined @click="$emit('update:isPayment',false)">ยกเลิก</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <CancelOrder v-model="isCancel" :data-update="orderDetail"
                 @onUpdate="$emit('onAdd');$emit('update:isPayment',false)"/>
  </div>
</template>

<script>
import { api } from "@/services/api";
import { ref, watch, onMounted } from "@vue/composition-api"
import store from "@/store"
import { required } from "@core/utils/validation";
import { mdiCalendar, mdiClockTimeFour, mdiCloudUploadOutline, mdiPencilOutline, mdiDeleteOutline } from "@mdi/js";
import { formatPrice } from "@/services/filters"

import CancelOrder from "@/views/manager/order/orderList/CancelOrder";

export default {
  model: {
    prop: 'isPayment',
    event: 'update:isPayment'
  },
  props: {
    isPayment: {
      type: Boolean,
      default: false
    },
    orderDetail: {
      type: Object,
      default: () => {
      }
    },
  },
  filters: {
    formatPrice
  },
  components: { CancelOrder },
  setup(props, { root, emit }) {
    const moment = require('moment')
    const bankList = ref([])
    const bankSelection = ref({})
    const transferName = ref('')
    const paymentDate = ref(moment(new Date()).format('YYYY-MM-DD'))
    const paymentTime = ref(moment(new Date()).format('HH:mm'))
    const menu1 = ref(false)
    const menu2 = ref(false)
    const blob_image = ref(null)
    const slipImage = ref(null)
    const dataBank = ref(null)
    const loading = ref(false)
    const isCancel = ref(false)
    const formAddPayment = ref(null)
    const currencyList = ref([])
    const currencySelection = ref({})
    const amount = ref(0)


    onMounted(() => {
      getBankList()
      getCurrencies()
    })

    watch(() => props.isPayment, (val) => {
      if (val) {
        getBankList()
        getCurrencies()
        slipImage.value = null
        blob_image.value = null
        console.log('data', props.orderDetail.order_total)
        props.orderDetail.order_total = formatPrice(props.orderDetail.order_total)
        transferName.value = ''
        paymentDate.value = moment(new Date()).format('YYYY-MM-DD')
        paymentTime.value = moment(new Date()).format('HH:mm')
      }
    })


    const getCurrencies = async () => {
      api.get({
        path: '/currencys',
      }).then(({ data }) => {
        currencyList.value = data
        currencySelection.value = data.find(item => item.currency_code === 'KRW')
      }).catch(err => {
        console.log('error : ', err)
      })
    }

    const getBankList = async () => {
      api.get({
        path: 'banks',
      }).then(({ data }) => {
        bankList.value = data
        bankSelection.value = data[0]
      })
    }

    const previewFiles = event => {
      slipImage.value = null
      slipImage.value = event.target.files[event.target.files.length - 1]
      blob_image.value = URL.createObjectURL(
          event.target.files[event.target.files.length - 1],
      )
    }

    const confirmPayment = () => {
      const isFormValid = formAddPayment.value.validate()
      if (!isFormValid) return
      loading.value = true
      const formData = new FormData()
      formData.append('bank_id', bankSelection.value.bank_id)
      formData.append('order_payment_slip_confirm', transferName.value)
      formData.append('order_id', props.orderDetail.order_id)
      formData.append('currency_id', currencySelection.value.currency_id)
      formData.append('order_payment_total', props.orderDetail.sum_total_transportation)
      formData.append('order_payment_slip', slipImage.value)
      formData.append('order_payment_date', paymentDate.value)
      formData.append('order_payment_time', paymentTime.value)
      api.post({
        path: '/confirmSlip',
        body: formData,
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        loading.value = false
        emit('update:isPayment', false)
        emit('onAdd')
      }).catch(err => {
        console.log('err', err)
        loading.value = false
      })
    }

    return {
      previewFiles,
      required,
      bankList,
      bankSelection,
      transferName,
      paymentDate,
      paymentTime,
      menu1,
      menu2,
      mdiCalendar,
      mdiClockTimeFour,
      blob_image,
      slipImage,
      mdiCloudUploadOutline,
      loading,
      confirmPayment,
      currencyList,
      isCancel,
      currencySelection,
      formAddPayment,
      amount,
      mdiPencilOutline, mdiDeleteOutline
    }
  },

}
</script>

<style scoped>
.set-block {
  max-width: 300px;
  max-height: 200px;
  border: 1px dashed #212121;
  border-radius: 10px;
  background-color: #d6d6d6;
}

.show-btns {
  color: rgba(255, 255, 255, 1) !important;
}
</style>
