<template>
  <div>
    <v-dialog v-model="isChecking" persistent max-width="500">
      <v-card>
        <iframe src="https://www-lotteglogis-com.translate.goog/home/reservation/tracking/linkView?InvNo=240880840340&_x_tr_sl=auto&_x_tr_tl=th&_x_tr_hl=en-US"></iframe>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  model: {
    prop: "isChecking",
    event: "update:isChecking",
  },
  props: {
    isChecking: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  setup(_, {root, emit}) {
    return {}
  },

}
</script>

<style scoped>

</style>
